import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from './createContext';

// The provider, which holds the page-wide store and its actions.
// Feel free to abstract actions and state away from this file.
class AppProvider extends Component {
  state = {
    open: false,
    showModal: () => this.setState({ open: true }),
    hideModal: () => this.setState({ open: false }),
    headerBackground: false,
    firstStyle: {
      background: 'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(250,248,246,0.75) 50%, rgba(250,248,246,1) 100%)',
      borderBottom: '1px solid #1a5d8f',
      minHeight: 'auto',
      padding: '1.5rem',
      transition: '0.5s',
    },
    secondStyle: {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      transition: '0.5s',
    },
  };

  listenScrollEvent = e => {
    //console.log('Current scroll: '+window.scrollY / window.innerHeight);
    if (window.scrollY / window.innerHeight > 0) {
      this.setState({headerBackground: true});
    } else {
      this.setState({headerBackground: false});
    }
  };

  componentDidMount() {
    //console.log('ADDED EVENT LISTENER');
    window.addEventListener('scroll', this.listenScrollEvent);
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
